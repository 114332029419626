import React from 'react';
import styled from 'styled-components';

import ImgAppStoreBadge from '../images/ImgAppStoreBadge';
import ImgGooglePlayBadge from '../images/ImgGooglePlayBadge';

const Badges = () => (
  <BadgesWrapper>
    <StoreBadgeImg available>
      <a href="https://itunes.apple.com/fi/app/shophilist/id1299430825?ls=1&mt=8">
        <ImgAppStoreBadge />
      </a>
    </StoreBadgeImg>
    <StoreBadgeImg available>
      <a href="https://play.google.com/store/apps/details?id=com.shophilist">
        <ImgGooglePlayBadge />
      </a>
    </StoreBadgeImg>
  </BadgesWrapper>
);

const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoreBadgeImg = styled.div`
  max-width: 120px;
  flex: 1;
  opacity: ${props => (props.available ? 1 : 0.2)};
  margin: 0 0.5rem;
`;

export default Badges;
