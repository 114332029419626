import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ImgAppStoreBadge = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "app-store-badge.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(
            width: 120
            height: 40
            traceSVG: {
              color: "#000"
              optTolerance: 0.2
              turdSize: 300
              turnPolicy: TURNPOLICY_MAJORITY
              threshold: 240
            }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return <Img fixed={data.placeholderImage.childImageSharp.fixed} {...props} />;
};
export default ImgAppStoreBadge;
